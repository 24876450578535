import React from 'react'
import "../CSS/Footer.css"
import FooterLogo from "../Images/Logo.png";
import { GrFacebookOption } from 'react-icons/gr';
import { FaLinkedinIn } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';
import { AiOutlineTwitter } from 'react-icons/ai';
import { AiFillYoutube } from 'react-icons/ai';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { IoIosCall } from 'react-icons/io';
import { RiSendPlaneFill } from 'react-icons/ri';
import FooterLogoMian from "../Images/Final Abss logo Golden.png";
// import { Link } from 'react-router-dom';


const FooterComponent = () => {
    return (
        <>
            {/* <!-- ===================================== Footer Section ====================== --> */}
            <div class="footer-basic">
                <footer className='Footer'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='Footer-col-1'>
                                <div className='Acting-logo-con'>
                                    <span>
                                        <img src={FooterLogo} alt="" />
                                    </span>
                                    <h4>Acting</h4>
                                </div>
                                <div className='Footer-Info'>
                                    <p>Our acting academy in andheri west Mumbai ,provides coaching skills on mastering character roles, basic forms of dance with acting techniques on speech pacing, rhythm, text analyzing on scripts with techniques to improve memory.</p>
                                </div>

                                <div className='Footer-Social'>
                                    <div className='Footer_Social-Media'>
                                        <span><a href="https://www.facebook.com/absstheatregroup/" target="_blank"><GrFacebookOption /></a></span>
                                        <span><a href='https://www.linkedin.com/in/abss-theatre-group-77a150190' target="_blank"><FaLinkedinIn /></a></span>
                                        <span><a href='https://www.instagram.com/absstheatregroup/?igshid=YmMyMTA2M2Y%3D' target="_blank"><AiFillInstagram /></a></span>
                                        <span><a href='https://twitter.com/AbssTheatre?s=08' target="_blank"><AiOutlineTwitter /></a></span>
                                        <span><a href='https://www.youtube.com/channel/UCVrdwxOpE0fR4UtEn7KdNFQ' target="_blank"><AiFillYoutube /></a></span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            {/* <div className='Footer-col-2'>

                            </div> */}
                            <div className='Footer-Main-Image'>
                                <div className='FooterImageCon'>
                                    <div className='FooterLogo'>
                                        <img src={FooterLogoMian} alt="" />
                                    </div>
                                </div>
                                <ul class="list-inline">
                                    <li class="list-inline-item"><a href="/">Home</a></li>
                                    <li class="list-inline-item"><a href="/Alumni">Alumni</a></li>
                                    <li class="list-inline-item"><a href="/AboutUs">About Us </a></li>
                                    <li class="list-inline-item"><a href="/ContactUs">Contact Us</a></li>
                                </ul>

                                <p class="copyright">© Copyright ABSS. All Rights Reserved</p>
                                <p class="copyright"><a href="https://skdm.in/">Designed by <strong>Shree Krishna Digital Marketing</strong></a></p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='Footer-col-3'>
                                <div className='FooterContact'>
                                    <h4>Contact Us</h4>
                                </div>

                                <div className='Contact-Details'>
                                    <div className='Contact-Info'>
                                        <div className='icon'>
                                            <FaMapMarkedAlt />
                                        </div>
                                        <div className='FooterText'>
                                            <h4>A-206, 2nd Floor, Kotia Nirman, New Link Road, Near Fun Republic, Andheri (W) Mumbai, Maharashtra- 400053</h4>
                                        </div>

                                    </div>
                                </div>

                                <div className='Contact-Details'>
                                    <div className='Contact-Info'>
                                        <div className='icon'>
                                            <IoIosCall />
                                        </div>
                                        <div className='FooterText'>
                                            <h4>+91-8108844907</h4>
                                            <h4>+91-8828337111</h4>
                                            <h4>+022-4750-9698</h4>
                                        </div>

                                    </div>
                                </div>

                                <div className='Contact-Details'>
                                    <div className='Contact-Info'>
                                        <div className='icon'>
                                            <RiSendPlaneFill />
                                        </div>
                                        <div className='FooterText'>
                                            <h4>info@abssgroup.com</h4>
                                            <h4>abssgroup1@gmail.com</h4>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    )
}

export default FooterComponent;