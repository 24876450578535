import React, { useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import "../CSS/ContactPage.css";
import { IoMail } from "react-icons/io5";
import { IoCall } from "react-icons/io5";
import { FaMapMarkedAlt } from "react-icons/fa";
import { Form, Input, Button } from 'antd';
import $ from "jquery";
import Footer from "../Component/FooterComponent"
const ContactPage = () => {
    const [form] = Form.useForm();

    const submitForm = (values) => {
        console.log("conatct", values)
        var body = '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#821f40">ABSS</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#821f40"><strong>Name:</strong></td><td style="text-align:left">' + values.custName + '</td></tr><tr><td style="text-align:left;color:#821f40"><strong>Email Id:</strong></td><td style="text-align:left">' + values.custEmail + '</td></tr><tr><td style="text-align:left;color:#821f40"><strong>Contact No:</strong></td><td style="text-align:left">' + values.custPhone + '</td></tr><tr><td style="text-align:left;color:#821f40"><strong>Message:</strong></td><td style="text-align:left">' + values.custMessage + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at ABSS</p></div></body></html>';

        $.post(
            'https://skdm.in/server/v1/send_lead_mail.php',
            {
                toEmail: 'info@abssgroup.com',
                fromEmail: 'skdmlead@gmail.com',
                bccMail: 'skdmlead@gmail.com',
                mailSubject: 'New Lead generation',
                mailBody: body,
                accountName: 'ABSS',
                accountLeadSource: 'http://www.abssgroup.com/',
                accountLeadName: values.custName,
                accountLeadPhone: values.custPhone,
                accountLeadEmail: values.custEmail,
                accountLeadMsgs: values.custMessage,
            },
            function (dataa, status) {
                console.log('data :' + dataa);
                console.log('name:' + values.custName);
            }
        );

        alert('Your Form has Submitted Our team will contact with You soon.');
    };

    return (
        <>
            {/* /////////////////////////// Section 1 /////////////////////////////////////////// */}
            <section className='ContactUs-Section'>
                <div className='ContactUs-banner-Image'>
                    <div className='ContactUs-Banner-text'>
                        <h1>Conatct Us</h1>
                    </div>
                </div>
            </section>

            {/* /////////////////////////// Section 2 /////////////////////////////////////////// */}

            <section className="contact-page-section-2">
                <div className="container">
                    <div className="contact-page-section-main-con">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="contact-page-section-contact-card-body">
                                    <div className="contact-page-section-contact-card">
                                        <div className="contact-page-section-head-con">
                                            <h3>Mumbai Office</h3>
                                        </div>
                                        <div className="contact-page-section-contact-detils-con">
                                            <FaMapMarkedAlt />
                                            <div className="contact-page-section-details-text">
                                                <a>A-206, 2nd Floor, Kotia Nirman, New Link Road, Near Fun Republic, Andheri (W) Mumbai, Maharashtra- 400053</a>
                                            </div>
                                        </div>
                                        <div className="contact-page-section-contact-detils-con">
                                            <IoMail />
                                            <div className="contact-page-section-details-text">
                                                <a>info@abssgroup.com</a>
                                                <a>abssgroup1@gmail.com</a>
                                            </div>
                                        </div>
                                        <div className="contact-page-section-contact-detils-con">
                                            <IoCall />
                                            <div className="contact-page-section-details-text">
                                                <a>+022-4750-9698</a>
                                                <a>+91-8108844907</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="contact-page-section-contact-card-body">
                                    <div className="contact-page-section-contact-card">
                                        <div className="contact-page-section-head-con">
                                            <h3>Lucknow Office</h3>
                                        </div>
                                        <div className="contact-page-section-contact-detils-con">
                                            <FaMapMarkedAlt />
                                            <div className="contact-page-section-details-text">
                                                <a>Gandhi Smarak Nidhi, Gandhi Bhawan, M.G. Road, Lucknow, Uttar Pradesh - 226001</a>
                                            </div>
                                        </div>
                                        <div className="contact-page-section-contact-detils-con">
                                            <IoMail />
                                            <div className="contact-page-section-details-text">
                                                <a>infolko@abssgroup.com</a>
                                                <a>absstheatregroup@gmail.com</a>
                                            </div>
                                        </div>
                                        <div className="contact-page-section-contact-detils-con">
                                            <IoCall />
                                            <div className="contact-page-section-details-text">
                                                <a>+91 7888091111</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-page-section-contact-form-and-map">
                <div className="container">
                    <div className="contact-page-section-details-map-con">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="contact-page-section-map-01">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.345879955665!2d72.81928841423147!3d19.13633128705328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b6174c618491%3A0xbea814153ce5ae43!2sABSS%20Acting%20Institute%20%26%20Theatre%20Group!5e0!3m2!1sen!2sin!4v1663045499272!5m2!1sen!2sin" style={{ width: "100%", height: "100%" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='map  '></iframe>
                                </div>
                                <div className="contact-page-section-map-01">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14237.257077604805!2d80.9279881!3d26.8617546!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfd52e1aa2127%3A0x59e68138905942c0!2sGandhi%20Bhavan!5e0!3m2!1sen!2sin!4v1720533412589!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" style={{ width: "100%", height: "100%" }} referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='mailTitle'>
                                    <h5>SEND US AN EMAIL</h5>
                                </div>
                                <div className='form'>
                                    <Form form={form} onFinish={submitForm}>
                                        <Form.Item name="custName" rules={[{ required: true, message: 'Please enter your name!' }]}>
                                            <Input placeholder="Name" />
                                        </Form.Item>
                                        <Form.Item name="custPhone" rules={[{ required: true, message: 'Please enter your phone number!' }]}>
                                            <Input placeholder="Phone" />
                                        </Form.Item>
                                        <Form.Item name="custEmail" rules={[{ required: true, message: 'Please enter your email!' }, { type: 'email', message: 'Invalid email address' }]}>
                                            <Input placeholder="Email Address" />
                                        </Form.Item>
                                        <Form.Item name="custMessage" rules={[{ required: true, message: 'Please enter your message!' }]}>
                                            <Input.TextArea placeholder="Message" rows={5} />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
};

export default ContactPage;
