import React from 'react';
import "./CameraCourses.css";
import FooterComponent from '../FooterComponent';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';
import { Button, Form, Input } from 'antd';
import styleImage from "../../Images/play.png";
import "../../Component/Courses/CameraCourses.css";
import cameracourses1 from "../../Images/Courses/camera courses 1.jpg";
import cameracourses2 from "../../Images/Courses/camera courses 2.jpg";
import cameracourses3 from "../../Images/Courses/camera courses 4.jpg";
import cameracourses4 from "../../Images/Courses/camera courses 5.jpg";
import cameracourses5 from "../../Images/Courses/camera courses 6.jpg";
import $ from 'jquery';
import TextArea from 'antd/es/input/TextArea';

const CameraCourses = () => {

    const [form] = Form.useForm();

    const submitFrom = (values) => {
        var body = '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#821f40">ABSS</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#821f40"><strong>Name:</strong></td><td style="text-align:left">' + values.name + '</td></tr><tr><td style="text-align:left;color:#821f40"><strong>Courses:</strong></td><td style="text-align:left">' + values.courses + '</td></tr><tr><td style="text-align:left;color:#821f40"><strong>Contact No:</strong></td><td style="text-align:left">' + values.contact + '</td></tr><tr><td style="text-align:left;color:#821f40"><strong>Message:</strong></td><td style="text-align:left">' + values.message + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at ABSS</p></div></body></html>';

        $.post("https://skdm.in/server/v1/send_lead_mail.php", {
            toEmail: "info@abssgroup.com",
            fromEmail: "skdmlead@gmail.com",
            bccMail: "skdmlead@gmail.com",
            mailSubject: "New Customer Lead",
            mailBody: body,
            accountName: "ABSS",
            accountLeadSource: "https://abssgroup.com/",
            accountLeadName: values.name,
            accountLeadEmail: values.email,
            accountLeadPhone: values.contact,
        },

            function (dataa, status) {
                console.log('data :' + dataa);
                console.log("name:" + values);
            });

        alert("Your Form has Submitted Our team will contact with You  soon.");
        form.resetFields();
        values.preventDefault()
        return false;
    }


    return (
        <>
            <section className='acting-courses-section-1-main-con cameracourses-section-1-back-image'>
                <div className='acting-courses-section-banner-con'>
                    <div className='action-courses-section-text'>
                        <h1>Camera courses</h1>
                        <h2>Camera courses</h2>
                    </div>
                </div>
            </section>

            <section className='acting-courses-section-2'>
                <div className='container'>
                    <div className='acting-courses-section-2-main-con'>
                        <div className='row'>
                            <div className='col-md-8'>
                                <div className='acting-courses-section-2-details-main-con'>
                                    <div className='acting-courses-section-2-details'>
                                        <Swiper
                                            spaceBetween={30}
                                            centeredSlides={true}
                                            autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            navigation={true}
                                            modules={[Autoplay, Pagination, Navigation]}
                                            className="mySwiper"
                                        >
                                            <SwiperSlide>
                                                <div className='acting-courses-section-2-swiper'>
                                                    <div className='acting-courses-section-2-details-images'>
                                                        <img src={cameracourses1} alt='actingcourses' />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='acting-courses-section-2-swiper'>
                                                    <div className='acting-courses-section-2-details-images'>
                                                        <img src={cameracourses2} alt='actingcourses' />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='acting-courses-section-2-swiper'>
                                                    <div className='acting-courses-section-2-details-images'>
                                                        <img src={cameracourses3} alt='actingcourses' />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='acting-courses-section-2-swiper'>
                                                    <div className='acting-courses-section-2-details-images'>
                                                        <img src={cameracourses4} alt='actingcourses' />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='acting-courses-section-2-swiper'>
                                                    <div className='acting-courses-section-2-details-images'>
                                                        <img src={cameracourses5} alt='actingcourses' />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>

                                    <div className='acting-courses-section-2-details-text-con'>

                                        <div className='acting-courses-section-2-details-text'>
                                            <div className='acting-courses-section-2-details-text-image'>
                                                <img src={styleImage} alt='' />
                                            </div>
                                            <p>Advanced-level camera curriculum focusing on confidence-building in front of the camera.</p>
                                        </div>

                                        <div className='acting-courses-section-2-details-text'>
                                            <div className='acting-courses-section-2-details-text-image'>
                                                <img src={styleImage} alt='' />
                                            </div>
                                            <p>Emphasis on collaborative skills with camera, sound, and lighting setups.</p>
                                        </div>

                                        <div className='acting-courses-section-2-details-text'>
                                            <div className='acting-courses-section-2-details-text-image'>
                                                <img src={styleImage} alt='' />
                                            </div>
                                            <p>Training based on the latest techniques in the film industry.</p>
                                        </div>

                                        <div className='acting-courses-section-2-details-text'>
                                            <div className='acting-courses-section-2-details-text-image'>
                                                <img src={styleImage} alt='' />
                                            </div>
                                            <p>Experienced instructors with directorial roles in television and film.</p>
                                        </div>

                                        <div className='acting-courses-section-2-details-text'>
                                            <div className='acting-courses-section-2-details-text-image'>
                                                <img src={styleImage} alt='' />
                                            </div>
                                            <p>Practical exercises including shooting short films outdoors for comprehensive learning.</p>
                                        </div>

                                        <div className='acting-courses-section-2-details-text'>
                                            <div className='acting-courses-section-2-details-text-image'>
                                                <img src={styleImage} alt='' />
                                            </div>
                                            <p>Concentration on character immersion, improvisation, and body language adaptation.</p>
                                        </div>

                                        <div className='acting-courses-section-2-details-text'>
                                            <div className='acting-courses-section-2-details-text-image'>
                                                <img src={styleImage} alt='' />
                                            </div>
                                            <p>Manipulation of voice graph and dialogue variations, with a focus on Hindi, regional languages, and Urdu.</p>
                                        </div>

                                        <div className='acting-courses-section-2-details-text'>
                                            <div className='acting-courses-section-2-details-text-image'>
                                                <img src={styleImage} alt='' />
                                            </div>
                                            <p>Emphasis on facial expressions, emotions, reactions, and character portrayal in various moods.</p>
                                        </div>

                                        <div className='acting-courses-section-2-details-text'>
                                            <div className='acting-courses-section-2-details-text-image'>
                                                <img src={styleImage} alt='' />
                                            </div>
                                            <p>Technical aspects covered including camera handling, facial control, audition video production, and showreel creation.</p>
                                        </div>

                                        <div className='acting-courses-section-2-details-text'>
                                            <div className='acting-courses-section-2-details-text-image'>
                                                <img src={styleImage} alt='' />
                                            </div>
                                            <p>Preparation for shooting scenarios in short films, web series, films, and television shows.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='stock-details-section-2-right-side-main-con'>
                                    <div className='stock-details-2-right-side-form'>
                                        <div className='stock-details-2-right-side-form-title'>
                                            <h3>Camera courses</h3>
                                        </div>
                                        <div className='stock-details-2-right-side-form-price'>
                                            <div className='stock-details-2-form-price-and-quent'>
                                            <Form
                                                    layout='vertical'
                                                    initialValues={{
                                                        courses: 'Camera Course'
                                                    }}
                                                    onFinish={submitFrom}
                                                >
                                                    <Form.Item name="name" label="Name">
                                                        <Input
                                                            placeholder='Name'
                                                        />
                                                    </Form.Item>
                                                    <Form.Item name="contact" label="Contact">
                                                        <Input
                                                            placeholder='Contact No.'
                                                        />
                                                    </Form.Item>
                                                    <Form.Item name="courses" label="Courses Name">
                                                        <Input
                                                            placeholder='Courses'
                                                            disabled
                                                        />
                                                    </Form.Item>
                                                    <Form.Item name="message" label="Message">
                                                        <TextArea
                                                        rows={4}
                                                            placeholder='Message'
                                                        />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Button htmlType='submit'>
                                                            Enquire Now
                                                        </Button>
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterComponent />
        </>
    )
}

export default CameraCourses