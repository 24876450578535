import React from 'react'
import "../CSS/HomePage.css"
import ReactPlayer from 'react-player';
import Footer from "../Component/FooterComponent";
import { Link } from 'react-router-dom';
import group01 from "../Images/Home/Classes For/classesFor1.png";
import group02 from "../Images/Home/Classes For/classesFor2.png";
import group03 from "../Images/Home/Classes For/classesFor3.png";
import group04 from "../Images/Home/Classes For/classesfor4.png";
// Gallery Images

// about Image  
import aboutImage1 from "../Images/Home/About/AboutUs1.png";
import aboutImage2 from "../Images/Home/About/AboutUs2.jpeg";

import { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import TestimonialData from '../Component/TestimonialData';
import SectionSepratorB from "../Images/section-separator-bottom.png"


// Teachers Images 
import AshwaniShukla from "../Images/ABSS Teachers/Ashwani Shukla.png";
import NishthaTiwari from "../Images/ABSS Teachers/Nishtha Tiwari.png";
import RajendraTwari from "../Images/ABSS Teachers/Rajendra Twari.png";


import Gallery1 from "../Images/home gallery/Slide Pics16.JPG";
import Gallery2 from "../Images/home gallery/Slide Pics37.jpg";
import Gallery3 from "../Images/home gallery/Slide Pics35.jpg";
import Gallery4 from "../Images/gallery square/TheaterFestival1.JPG";
import Gallery5 from "../Images/gallery square/TheaterFestival3.JPG";
import Gallery6 from "../Images/gallery square/TheaterFestival19.JPG";

import Blog1 from "../Images/Blog/blog2.png";
import Blog2 from "../Images/Blog/blog3.png";
import Blog3 from "../Images/Blog/blog4.png";

import banner01 from '../Images/ABSS WEBSITE BANNER.png';
import banner02 from '../Images/ABSS WEBSITE BANNER1.png';


const HomePage = () => {

    const [testimonialData, settestimonialData] = useState(TestimonialData);

    return (
        <>


            {/* /////////////////////////////// section1 /////////////////////////////////// */}

            <div className='Home-new-section-banner-image-con'>
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className='Home-banner'>
                            <img src={banner01} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='Home-banner'>
                            <img src={banner02} />
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            {/* ///////////////////////////////////////////////// home section Blog /////////////////////////////////////////////////////// */}

            <section className='home-blog-section'>
                <div className='container'>
                    <div className='home-blog-section-blog-head-con'>
                        <h2>Our Latest Blog & Events</h2>
                    </div>
                    <div className='home-section-blog-main-con'>
                        <div className='row'>
                            <div className='col-md-4 col-sm-6'>
                                <Link to="/acting-courses">
                                    <div className='home-section-blog-main-image-con'>
                                        <img src={Blog3} />
                                    </div>
                                </Link>
                            </div>
                            <div className='col-md-4 col-sm-6'>
                                <Link to="/theater-group">
                                    <div className='home-section-blog-main-image-con'>
                                        <img src={Blog1} />
                                    </div>
                                </Link>
                            </div>
                            <div className='col-md-4 col-sm-6'>
                                <Link to="/classes-for-children">
                                    <div className='home-section-blog-main-image-con'>
                                        <img src={Blog2} />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* //////////////////////////////////////////// SECTION 2 /////////////////////////////////////////// */}

            <section className="Home-Section2" id="destination">
                <div className="container">
                    <div className='ClassesFor'>
                        <h3>Where talent meets training, and dreams come alive</h3>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-12 col-sm-6 col-12 mb-3">
                                    <div className='ClassDetail'>
                                        <div className="categryBanner" style={{ height: "250px", margin: "auto", background: "url(" + group01 + ")", backgroundSize: "100% 100%", webkitBackgroundSize: "cover", backgroundPosition: "center" }}>
                                            {/* <h3>Diploma in Acting</h3> */}
                                        </div>
                                        <div className='ClassInfo'>
                                            <h5>Diploma in Acting</h5>
                                            <p>"Aiming to be an Actor than ABSS is your  right mentor as an Actor". Become a pro actor by taking up special courses at rates that you can afford. Come, explore your creativity! As we feel our success in the success of our Students and celebrate feeling proud in dedicating them to the Industry.</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-md-12 col-sm-6 col-12 mb-3">
                                    <div className='ClassDetail'>
                                        <div className="categryBanner" style={{ height: "250px", margin: "auto", background: "url(" + group02 + ")", backgroundSize: "100% 100%", webkitBackgroundSize: "cover", backgroundPosition: "center" }}>
                                            {/* <h3>Acting Workshop</h3> */}
                                        </div>
                                        <div className='ClassInfo'>
                                            <h5>Acting Workshop</h5>
                                            <p>The Success Secret of ABSS is its Acting Workshops. As to Become an actor, it is very important to be aware of basics of acting. We provide acting workshops to aspirant actors in order to enhance acting skills. We make them practice as we believe in it.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 d-flex mb-3 mt-3">
                            <div className='mid-image'>

                                <div className="categryBanner">
                                    <ReactPlayer controls width='100%' height='100%' url="https://www.youtube.com/watch?v=I3vbvjYAcfM" className="react-player" style={{ borderRadius: "20px" }} />

                                </div>

                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-12 col-sm-6 col-12 mb-3">
                                    <div className='ClassDetail'>
                                        <div className="categryBanner" style={{ height: "250px", margin: 'auto', background: "url(" + group03 + ")", backgroundSize: "100% 100%", webkitBackgroundSize: "cover", backgroundPosition: "center" }}>
                                            {/* <h3>Theatre Acting Classes</h3> */}
                                        </div>
                                        <div className='ClassInfo'>
                                            <h5>Theatre Acting Classes</h5>
                                            <p>Most of the actors start from a role at Theaters and make their debut in the Industry Hence we give special preference to train you at the Theater making you a master in learning the art & technique to steal the heart of audiences by being The Greatest Showman and a Perfect Artist of the Big Screen.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 col-sm-6 col-12">
                                    <div className='ClassDetail'>
                                        <div className="categryBanner" style={{ height: "250px", margin: "auto", background: "url(" + group04 + ")", backgroundSize: "100% 100%", webkitBackgroundSize: "cover", backgroundPosition: "center" }}>
                                            {/* <h3>Weekend Acting Class</h3> */}
                                        </div>
                                        <div className='ClassInfo'>
                                            <h5>Weekend Acting Class</h5>
                                            <p>Actor swings the mind of audience by the moves of his body by performing  Dance.  As dance is the most crucial part of the film industry, We Give Dance Training to Aspirant Dancers in many forms: Salsa, Jazz, Hip-Hop, and Contemporary, etc.We have not just trained actors but also made   "Dance Specialists"   at ABSS</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <div className='mid-Image'>
                <img src={SectionSepratorB} alt='' />

            </div>


            {/* /////////////////////////////////// Section 3 ///////////////////////////////////// */}

            <section className='Home-welcome-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='welcome-Image-con-Home'>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-6'>
                                        <div className='Home-AB-FImage'>
                                            <img src={aboutImage1} alt="" />
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-sm-6'>
                                        <div className='Home-AB-SImage'>
                                            <img src={aboutImage2} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='welcome-material'>
                                <div className='welcome-detail'>
                                    <div className='welcome-heading'>
                                        <h2>About Us</h2>
                                    </div>
                                    <div className='welcome-discription'>
                                        <p>We had started ABSS ( Akhil Bhartiya Sanskritik Sansthan ) with eleven artists at Gandhi Bhawan Lucknow showing a rocking 'stage play' on Gandhi Bhawan Jayanti 2 Oct 1990. Today, We have done more than 1500 shows with over 5000 artists at various locations across India. Currently, We are situated at Andheri W. We focus on Inner acting and outer acting to enhance the skills of our artists. Hence, we bring the best in them. Listening and understanding our customers and providing tailored solutions is something that makes us stand apart.</p>
                                    </div>
                                    <div className='Welcome-more-button'>
                                        <Link to="/AboutUs"><span>READ MORE</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* /////////////////////////////////// Section 4 ///////////////////////////////////// */}

            <div className='Home-Section5'>
                <div className='container'>
                    <div className='Home-Gallery'>
                        <div className='row'>
                            <div className='col'>
                                <div className='ImageGallery'>
                                    <div className='ImageGalleryBar'>
                                        <div className='ImageGallery-Text'>
                                            <h6>COLLECTION</h6>
                                            <h3>Our Image Gallery</h3>
                                        </div>
                                        <div className='BackImage-Text'>
                                            <h1>Collection</h1>
                                        </div>
                                    </div>
                                    <div className='Gallery-Right-Button'>
                                        <Link to="/Images"> <h5>View All</h5></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row '>

                        <div className='col-md-6'>
                            <div className='FImages'>
                                <img src={Gallery1} alt="" />
                            </div>
                            {/* <div className='GalleryInfo'>

                                        </div> */}
                        </div>
                        <div className='col-md-3'>
                            <div className='FImages'>
                                <img src={Gallery3} alt="" />
                            </div>
                            {/* <div className='GalleryInfo'>

                                        </div> */}
                        </div>
                        <div className='col-md-3'>
                            <div className='FImages'>
                                <img src={Gallery4} alt="" />
                            </div>
                            {/* <div className='GalleryInfo'>

                                        </div> */}
                        </div>
                        <div className='col-md-3'>
                            <div className='FImages'>
                                <img src={Gallery5} alt="" />
                            </div>
                            {/* <div className='GalleryInfo'>

                                        </div> */}
                        </div>
                        <div className='col-md-6'>
                            <div className='FImages'>
                                <img src={Gallery2} alt="" />
                            </div>
                            {/* <div className='GalleryInfo'>

                                        </div> */}
                        </div>
                        <div className='col-md-3'>
                            <div className='FImages'>
                                <img src={Gallery6} alt="" />
                            </div>
                            {/* <div className='GalleryInfo'>

                                        </div> */}
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>

            {/* video gallery  */}

            <div className='Home-Section5'>
                <div className='container'>
                    <div className='Home-Gallery'>
                        <div className='row'>
                            <div className='col'>
                                <div className='ImageGallery'>
                                    <div className='ImageGalleryBar'>
                                        <div className='ImageGallery-Text'>
                                            <h6>COLLECTION</h6>
                                            <h3>Our Video Gallery</h3>
                                        </div>
                                        <div className='BackImage-Text'>
                                            <h1>Collection</h1>
                                        </div>
                                    </div>
                                    <div className='Gallery-Right-Button'>
                                        <Link to="/Images"> <h5>View All</h5></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='FImages'>
                                {/* <ReactPlayer controls width='100%' height='100%' url="https://youtu.be/FBix_3lPCT4" /> */}
                                <ReactPlayer controls width='100%' height='100%' url="https://www.youtube.com/watch?v=9v4P1HyAFt4" />
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='FImages'>
                                <ReactPlayer controls width='100%' height='100%' url="https://youtu.be/YFFakPwCYN0" />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='FImages'>
                                <ReactPlayer controls width='100%' height='100%' url="https://www.youtube.com/watch?v=2DMm7VaCraA" />
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='FImages'>
                                {/* <ReactPlayer controls width='100%' height='100%' url="https://youtu.be/W2-ycWozznU" /> */}
                                <ReactPlayer controls width='100%' height='100%' url="https://www.youtube.com/watch?v=I3vbvjYAcfM" />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='FImages'>
                                <ReactPlayer controls width='100%' height='100%' url="https://youtu.be/D5RuljmYzVg" />
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='FImages'>
                                {/* <ReactPlayer controls width='100%' height='100%' url="https://youtu.be/6ApWhrEmr0Q" /> */}
                                <ReactPlayer controls width='100%' height='100%' url="https://www.youtube.com/watch?v=jMbBTfUseuU" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* /////////////////////////////////////// Our-team /////////////////////////////////////// */}
            <section className='Our-Team-Section'>
                <div className='container'>
                    <div className='Our-Team-heading-Container'>
                        <div className='OurTeam-Heading'>
                            <h5>Our Team</h5>
                            <p>Our great acting & theater group will have works performed by professional ensembles</p>
                        </div>
                        {/* <div className='OurTeam-Back'>
                            <h1>Team</h1>
                        </div> */}
                    </div>

                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='Our-Team-details'>
                                <div className='Our-Team-image'>
                                    <img src={RajendraTwari} alt="" />
                                    <div className='our-Team-Name'>
                                        <h4>Rajendra Twari</h4>
                                    </div>
                                </div>
                                <div className='Our-Team-Info'>
                                    <h4>Rajendra Twari</h4>
                                    <h6>Director</h6>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='Our-Team-details'>
                                <div className='Our-Team-image'>
                                    <img src={NishthaTiwari} alt="" />
                                    <div className='our-Team-Name'>
                                        <h4>Nishtha Tiwari</h4>
                                    </div>
                                </div>
                                <div className='Our-Team-Info'>
                                    <h4>Nishtha Tiwari</h4>
                                    <h6>Creative Head & Camera Class Teacher</h6>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='Our-Team-details'>
                                <div className='Our-Team-image'>
                                    <img src={AshwaniShukla} alt="" />
                                    <div className='our-Team-Name'>
                                        <h4>Ashwani Shukla</h4>
                                    </div>
                                </div>
                                <div className='Our-Team-Info'>
                                    <h4>Ashwani Shukla</h4>
                                    <h6>Manager</h6>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            {/* ////////////////////////////// Testimonial /////////////////////////////////////////// */}

            <section className='Testimonial-Section'>
                <div className='container'>
                    <div className='Testimonials-Main-Heading'>
                        <h4>Testimonials</h4>
                        <h3>Testimonials</h3>
                    </div>
                    {/* <div className='row'> */}
                    {/* <div className='col-md-4'>
                            <div className='testimonial_Image'>
                                <div className='testimonial-Image-Container'>
                                    <img src={TestimonialImage} alt="" />
                                </div>
                            </div>
                        </div> */}
                    {/* <div className='col-md-8'> */}
                    <div className='testimonial-Container'>
                        <Swiper
                            spaceBetween={30}
                            centeredSlides={true}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                        >
                            {
                                testimonialData.map((ele) => {
                                    const { Name, Image, Discription01, Discription02, Discription03, Discription04, Discription05 } = ele

                                    return (
                                        <>
                                            <SwiperSlide>
                                                <div className='testimonial-detail'>
                                                    <div className='testimonial-text'>
                                                        <h4>{Discription01}</h4>
                                                        <h4>{Discription02}</h4>
                                                        <h4>{Discription03}</h4>
                                                        <h4>{Discription04}</h4>
                                                        <h4>{Discription05}</h4>
                                                    </div>
                                                    <div className='testimonial-profile'>
                                                        <div className='testimonial-Profile-image'>
                                                            <img src={Image} alt="" />
                                                        </div>
                                                        <div className='Testimonial-Name'>
                                                            <h4>{Name}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </>
                                    )
                                })
                            }
                        </Swiper>

                    </div>
                    {/* </div>
                    </div> */}
                </div>
            </section>

            <Footer />
            {/* //////////////////////////////////////////////////////////////////////////////////////////// */}

        </>
    )
}

export default HomePage 