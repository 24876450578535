
import React from 'react';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import NavComponent from './Component/NavComponent';
import HomePage from './Pages/HomePage';
import CrashCourse from './Pages/CrashCourse';
import Alumni from './Pages/Alumni';
import ContactUs from './Pages/ContactUs';
import ImageGallery from "./Pages/ImageGallery";
import VideoGallery from "./Pages/VideoGallery";
import DiplomaInActing from './Pages/DiplomaInActing';
import TheaterActing from "./Pages/TheaterActing";
import ClassForChildren from "./Pages/ClassForChildren";
import AboutUs from './Pages/AboutUs';
import Faculty from './Pages/Faculty';
import Admission from './Component/Admission/Admission.jsx';
import Testimonial from './Pages/Testimonial';
import Blog from './Pages/Blog';
import Events from './Pages/Events';
import EventInfo from "./Pages/EventInfo";
import RajendraTiwari from './Component/Faculty/RajendraTiwari';
import Placement from './Component/AboutPages/Placement';
import TheaterFestival from './Component/Festival/TheaterFestival';
import NishthaTiwari from './Component/Faculty/NishthaTiwari';
import AshwiniShukla from './Component/Faculty/AshwiniShukla';

// Courses
import ActingCourses from "./Component/Courses/ActingCourses.jsx";
import CoursesData from './Component/CoursesData.jsx';
import WhyAbss from './Component/Why/WhyAbss.jsx';
import Certificates from './Component/Certificates/Certificates.jsx';
import TheaterGroup from './Component/Courses/TheaterGroup.jsx';
import CameraCourses from './Component/Courses/CameraCourses.jsx';
import ActingWorkshop from './Component/Courses/ActingWorkshop.jsx';
import ClassesForChildren from './Component/Courses/ClassesForChildren.jsx';
import OnlineActingCourses from './Component/Courses/OnlineActingCourses.jsx';
import ContactPage from './Pages/ContactPage.jsx';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavComponent />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/Alumni' element={<Alumni />} />
          <Route path='/AboutUs' element={<AboutUs />} />
          <Route path='/ContactUs' element={<ContactUs />} />

          {/* Courses */}
          <Route path='/CrashCourse' element={<CrashCourse />} />
          <Route path='/TheaterGroup' element={<TheaterGroup />} />
          <Route path='/TheaterActing' element={<TheaterActing />} />
          <Route path='/ClassForChildren' element={<ClassForChildren />} />
          <Route path='/acting-courses' element={<ActingCourses />} />
          <Route path='/theater-group' element={<TheaterGroup />} />
          <Route path='/camera-courses' element={<CameraCourses />} />
          <Route path='/acting-workshop' element={<ActingWorkshop />} />
          <Route path='/classes-for-children' element={<ClassesForChildren />} />
          <Route path='/online-acting-courses' element={<OnlineActingCourses />} />

          {/* Gallery  */}
          <Route path='/Events' element={<Events />} />
          <Route path='/Images' element={<ImageGallery />} />
          <Route path='/Video' element={<VideoGallery />} />

          <Route path='/Faculty' element={<Faculty />} />
          <Route path='/admission' element={<Admission />} />
          <Route path='/Testimonial' element={<Testimonial />} />
          <Route path='/FAQ' element={<Blog />} />
          <Route path='/rajendra-tiwari' element={<RajendraTiwari />} />
          <Route path='/nishtha-tiwari' element={<NishthaTiwari />} />
          <Route path='/ashwini-shukla' element={<AshwiniShukla />} />
          {/* About Dropdown  */}

          <Route path='/placement' element={<Placement />} />
          <Route path='/theater-festival' element={<TheaterFestival />} />
          {/* ///////////// */}

          <Route path='/demo' element={<EventInfo />} />

          <Route path='/why-abss' element={<WhyAbss />} />
          <Route path='/certificates' element={<Certificates />} />
          <Route path='/contact-page' element={<ContactPage />} />

          {
            CoursesData.map((route, index) => {
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.slugs}
                    element={<ActingCourses />}
                  />
                </>
              )
            })
          }

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
